<template>
    <div class="header">
        <div style="margin: 5px;" class="gongnengtubiao">
            <img :src="$store.state.baseURL+'icon/zankai.png'" height="50px" v-if="this.$store.state.isCollapse === true" @click="EditCollapse">
            <img :src="$store.state.baseURL+'icon/yincang.png'" height="50px" v-else @click="EditCollapse">
        </div>
        <div style="font-size: 14px;">声明：本站资料仅供桌游爱好者学习交流，请勿用作商业用途。如有侵权行为，请联系网站作者进行删除。</div>
        <div>
            <el-button type="danger" plain @click="login_out">退出</el-button>
        </div>
    </div>
</template>

<script>
import '@/assets/style/index/index_header.css'

export default {
    name: 'IndexHeader',
    data() {
        return {}
    },
    computed: {},
    methods: {
        EditCollapse() {
            this.$store.commit(
                'edit_is_collapse',
                !this.$store.state.isCollapse
            )
        },
        login_out() {
            this.$confirm('是否确定退出系统？', '友情提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    sessionStorage.removeItem('token')
                    this.$router.replace('/login')
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>
<style >  
.header{
    font-family: PingFang SC;
    color: #535355;
}
.el-badge__content {
    font-size: 10px !important;
}
.gongnengtubiao  {
    cursor: pointer;
    transition: 0.5s;
}
</style>