<template class="menu_c">
    <el-menu
        :default-active="this.$route.fullPath"
        background-color="#66B3FF"
        text-color="#FFFFFF"
        active-text-color="#000000"
        :collapse="this.$store.state.isCollapse"
        router
    >
        <el-menu-item index="/">
            <img :src="$store.state.baseURL+'icon/shouye.png'" height="25px">
            <span slot="title" style="font-family: HarmonyOS_Sans_Black;">首页</span>
        </el-menu-item>

        <el-submenu
            v-for="v_team in menu"
            :index="v_team.name"
            :key="v_team.name"
        >
            <template slot="title">
                <img :src="$store.state.baseURL+'icon/'+v_team.icon+'.png'" height="25px">
                <span style="font-family: HarmonyOS_Sans_Black;"> {{ v_team.name }}</span>
            </template>
            <el-menu-item
                v-for="v_router in v_team.child"
                :index="'/' + v_router.router"
                :key="v_router.name"
            >
                <img :src="$store.state.baseURL+'icon/'+v_router.icon+'.png'" height="25px">
                <span style="font-family: HarmonyOS_Sans_Black;"> {{ v_router.name }}</span>
            </el-menu-item>
        </el-submenu>
    </el-menu>
</template>

<script>
import { MenuRequest } from '@/network/user.js'

export default {
    name: 'IndexMeu',
    data() {
        return {
            menu: {},
        }
    },
    computed: {},
    methods: {
        GetMenu() {
            MenuRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.menu = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
    },
    filters: {},
    props: {},
    created() {
        this.GetMenu()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style>
.el-menu .font_family{
    margin-right: 10px;
}
</style>